/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../pwasaas/src/style/abstract/variables';
@import '../../../../../pwasaas/src/style/abstract/media';
@import '../../../../../pwasaas/src/style/abstract/button';
@import '../../../../../pwasaas/src/style/abstract/loader';
@import '../../../../../pwasaas/src/style/abstract/icons';
@import '../../../../../pwasaas/src/style/abstract/image';
@import '../../../../../pwasaas/src/style/abstract/parts';

:root {

    @include mobile {
        --saas-header-usps-total-height: 0px;
        --saas-header-nav-links-position: relative;
    }
}

.SaasHeader {
    &-LogoWrapper {
        margin: 0 0 0 40px;
    }
}